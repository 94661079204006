import angular from 'angular';
import moment from 'moment';
import ngMessages from 'angular-messages';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
import TkrSpaCommon from '../../../common/tkr-spa';
import { TkrPersonService } from '../../../common/tkr-commonservices';
import { IbanService, IbanValidator } from '../../../common/ma-iban';
import { SmartFloat, SmartInteger } from '../../../common/ma-smartfloat-1.0';
import TkrPpCommon from '../../../pp/common/0-pp-common-module';
import TkrPpResources from '../../../pp/prcportal-resource-modules';
import { configureTranslateProvider } from '../../../pp/translation-helpers';
const subsidy_application_app = angular.module('ng-tkr-app-kalajoki', [
    TkrSpaCommon,
    ngMessages,
    ngTranslate,
    TkrPersonService,
    IbanValidator,
    IbanService,
    SmartFloat,
    SmartInteger,
    TkrPpResources,
    TkrPpCommon,
])
    .config(config)
    .config(configureTranslateProvider);
export default subsidy_application_app.name;
config.$inject = ['$stateProvider', '$urlRouterProvider'];
function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/err');
    $stateProvider
        .state('err', {
        url: '/err',
        template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
    })
        .state('root', {
        url: '',
        template: '',
        controller: 'AppRedirCtrl',
        controllerAs: 'vm'
    })
        .state('lomake', {
        url: '/:applicationId',
        template: require('./app.tpl.html'),
        controller: 'SubsidyApplicationController',
        controllerAs: 'vm',
        resolve: {
            app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                    return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                }],
            appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                    return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                }]
        }
    })
        .state('jatetty', {
        url: '/:applicationId/jatetty',
        template: require('./app-submitted.tpl.html'),
        controller: 'AppSubmittedController',
        controllerAs: 'vm'
    });
}
subsidy_application_app.controller('SubsidyApplicationController', SubsidyApplicationController);
SubsidyApplicationController.$inject = [
    'app',
    'appPeriod',
    'TkrPersonService',
    '$state',
];
function SubsidyApplicationController(app, appPeriod, TkrPersonService, $state) {
    var vm = this;
    vm.app = app;
    vm.vuosi = appPeriod.td.wnum.vuosi;
    vm.edvuosi = vm.vuosi - 1;
    vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm');
    vm.personRoles = TkrPersonService.personRoles;
    vm.chairmanRoles = TkrPersonService.chairmanRoles;
    vm.preprocessApp = function (data) {
        if (!data.chairman) {
            data.chairman = {};
        }
        if (!data.contactPerson) {
            data.contactPerson = {};
        }
        if (!data.td) {
            data.td = {};
        }
        if (!data.td.num) {
            data.td.num = {};
        }
        if (!data.td.wnum) {
            data.td.wnum = {};
        }
        if (!data.td.bools) {
            data.td.bools = {};
        }
        if (!data.td.text) {
            data.td.text = {};
        }
        if (data.td.num.lapikulkuPituus) {
            data.bypassRoad = true;
        }
    };
    vm.preprocessApp(vm.app);
    vm.contactIsChairman = function () {
        return TkrPersonService.isValidChairmanRole(vm.app.contactPerson.role);
    };
    vm.isSeparateChairmanRequired = function () {
        return !vm.contactIsChairman();
    };
    vm.postprocessApp = function (data) {
        if (!vm.isSeparateChairmanRequired()) {
            data.chairman = data.contactPerson;
        }
        if (data.td.bools.isEligible) {
            data.established = true;
        }
        if (!data.bypassRoad) {
            data.td.num.lapikulkuPituus = 0;
            delete data.td.text.lapikulkuPeruste;
            delete data.td.text.lapikulkuPerusteMuu;
        }
        if (data.td.text.lapikulkuPeruste !== 'muu') {
            delete data.td.text.lapikulkuPerusteMuu;
        }
    };
    vm.save = function () {
        vm.postprocessApp(vm.app);
        vm.app.applicationState = 'SUBMITTED';
        vm.app.$save().then(function (data) {
            $state.go('jatetty', { applicationId: data.id });
        });
    };
    vm.saveDraft = function () {
        vm.postprocessApp(vm.app);
        vm.app.applicationState = 'DRAFT';
        vm.app.$save().then(function (data) {
            vm.draftSaved = true;
        });
    };
}
