import angular from 'angular';
import './pp/app';
angular.module('tkr.custom')
    .decorator('customizationConfig', CustomizationConfigDecorator);
CustomizationConfigDecorator.$inject = ['$delegate'];
function CustomizationConfigDecorator($delegate) {
    const cc = $delegate;
    cc.prcListDetailTemplate = require('./mgmt/prc/prc-list.tpl.html');
    return cc;
}
